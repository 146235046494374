import React from 'react'
import { workspace } from '../LayoutDefault'
import { Sync } from '@material-ui/icons'
import { Container, ImgWorkspace, NameWoskapce } from './style'
import { Link } from 'react-router-dom'

interface IProps {
  Iworkspace: workspace | undefined
}

export const SwitchWorkspace: React.FC<IProps> = ({ Iworkspace }) => {
  return (
    <Container>
      {Iworkspace && (
        <>
          <ImgWorkspace src={Iworkspace.avatar_url} />
          <NameWoskapce>{Iworkspace.name}</NameWoskapce>
          <Link to='/backoffice/workspace' >
            <Sync onClick={() => sessionStorage.removeItem('@rocketpdv:workspace')} />
          </Link>
        </>
      )}
    </Container>
  )
}
